import { isSessionValid } from 'src/core/login/LoginService';
import config from 'data/config/config';
import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';

var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    FAVORITES_PAGE_KEY = _require.FAVORITES_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require.LIST_GROUPS_PAGE_KEY,
    KLIPSO_LEADS_PAGE_KEY = _require.KLIPSO_LEADS_PAGE_KEY,
    INBOX_PAGE_KEY = _require.INBOX_PAGE_KEY,
    LOGIN_PAGE_KEY = _require.LOGIN_PAGE_KEY,
    USER_DATA_PAGE_KEY = _require.USER_DATA_PAGE_KEY,
    SEARCH_PAGE_KEY = _require.SEARCH_PAGE_KEY,
    SEARCH_TAIGA_PAGE_KEY = _require.SEARCH_TAIGA_PAGE_KEY,
    MEDIAS_FEED_PAGE_KEY = _require.MEDIAS_FEED_PAGE_KEY,
    MEDIAS_PAGE_KEY = _require.MEDIAS_PAGE_KEY;
/**
 * Toolbar configuration per profile
 */


var _require2 = require('./profiles'),
    DEFAULT_PROFILE = _require2.DEFAULT_PROFILE,
    EXHIBITOR_PROFILE = _require2.EXHIBITOR_PROFILE,
    WEBAPP_EXH_PROFILE = _require2.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require2.WEBAPP_EVT_PROFILE,
    VISITOR_PROFILE = _require2.VISITOR_PROFILE,
    WEBAPP_SPK_PROFILE = _require2.WEBAPP_SPK_PROFILE;

export default function (profile, pageKey) {
  // Home does not have AppToolbar component
  if (pageKey === HOME_PAGE_KEY) {
    return {};
  }

  switch (profile) {
    // Same for every profile
    // case PROFILE_VISITEUR:
    // case PROFILE_EXPOSANT:
    // case PROFILE_PRESSE:
    default:
      return {
        homeButtonInToolbar: true,
        favButtonInToolbar: pageKey !== FAVORITES_PAGE_KEY,
        searchButtonInToolbar: pageKey !== SEARCH_PAGE_KEY && pageKey !== SEARCH_TAIGA_PAGE_KEY,
        menuButtonInToolbar: true,
        // listToolBarButton: homeToolbarIcon(),
        socialButtonInToolbar: config.SOCIAL && config.SOCIAL.hasSocialButtonInToolbar && (pageKey === MEDIAS_FEED_PAGE_KEY || pageKey === MEDIAS_PAGE_KEY),
        // Used when some profiles have access to reduced version of the app:
        // (e.g http://eurosatory-dev.mobile-spot.com/?profile=webapp-exh)
        rootCategoryButtonInToolbar: false && (pageKey === LIST_PAGE || pageKey === LIST_GROUPS_PAGE_KEY)
      };
  }
}

function getLoginIcon() {
  return {
    svg: {
      src: 'icons-svg/Profil.svg'
    },
    style: {
      width: '2em'
    },
    className: "icon-homeToolbarColor icon-homeToolbarColor ht-icon ht-login-icon".concat(isSessionValid() ? ' connected' : ''),
    label: getLabels().common.login,
    action: function action() {
      getBindedActions().navigate(isSessionValid() ? USER_DATA_PAGE_KEY : LOGIN_PAGE_KEY);
    },
    badge: null
  };
}

function getOAuth2Icon() {
  return {
    svg: {
      src: 'icons-svg/Profil.svg'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-oauth2-icon',
    label: getLabels().common.OAuth2,
    action: function action() {
      getBindedActions().navigate(USER_DATA_PAGE_KEY);
    },
    badge: null
  };
}

function getKlipsoSSOIcon() {
  return {
    svg: {
      src: 'icons-svg/Profil.svg'
    },
    style: {
      width: '2em'
    },
    className: "icon-homeToolbarColor ht-icon ht-login-icon".concat(isSessionValid() ? ' connected' : ''),
    label: getLabels().common.KlipsoSSO,
    action: function action() {
      getBindedActions().startLoginKlipsoSSO();
    },
    badge: null
  };
}

function getFavoritesIcon() {
  return {
    svg: {
      src: 'icons-svg/Fav.svg',
      st0Class: 'home-toolbar-st0'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon  ht-favorites-icon',
    label: getLabels().common.goToFavorites,
    action: function action() {
      getBindedActions().navigate(FAVORITES_PAGE_KEY);
    },
    badge: null
  };
}

function getInboxIcon() {
  return {
    svg: {
      src: 'icons-svg/homeToolBar/envelope-solid.svg'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-inbox-icon',
    label: getLabels().common.goToInbox,
    action: function action() {
      getBindedActions().navigate(INBOX_PAGE_KEY);
    },
    badge: null // global.isCordovaContext ? this.props.inboxCount : null,

  };
}

function getKlipsoLeadsIcon() {
  return {
    svg: {
      src: 'icons-svg/KlipsoLead.svg'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-kl-icon',
    label: getLabels().common.KlipsoLeads,
    action: function action() {
      getBindedActions().navigate(KLIPSO_LEADS_PAGE_KEY);
    },
    badge: null
  };
}

function getContactScanIcon() {
  return {
    svg: {
      src: 'icons-svg/homeToolBar/qrcode-reader.svg',
      st0Class: 'home-toolbar-st0'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-kl-icon',
    label: getLabels().common.contactScan,
    action: function action() {
      getBindedActions().scanContact();
    },
    badge: null
  };
}

function getSearchIcon() {
  return {
    svg: {
      src: 'icons-svg/Search.svg',
      st0Class: 'home-toolbar-st0'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-search-icon',
    label: getLabels().common.goToSearch,
    action: function action() {
      getBindedActions().navigate(SEARCH_PAGE_KEY);
    },
    badge: null
  };
}

function getMenuIcon() {
  return {
    svg: {
      src: 'icons-svg/appToolBar/Burger.svg'
    },
    style: {
      width: '2em'
    },
    className: 'ht-icon ht-menu-icon',
    label: getLabels().common.openMenu,
    action: function action() {
      getBindedActions().openMenu(HOME_PAGE_KEY);
    },
    badge: null
  };
}
/**
 * Toolbar configuration for home
 */


export function homeToolbarIcon(profile) {
  var array = []; // OAuth2 Icon
  // if (config.OAuth2 && config.OAuth2.FEATURE_ENABLED) array.push(getOAuth2Icon());
  // Klipso Leads SSO Icon

  if (config.SSO && config.SSO.FEATURE_ENABLED) array.push(getKlipsoSSOIcon()); // login Icon

  if (config.LOGIN && config.LOGIN.FEATURE_ENABLED) array.push(getLoginIcon()); // Favorite Icon

  array.push(getFavoritesIcon()); // Inbox Icon

  array.push(getInboxIcon());

  switch (profile) {
    case DEFAULT_PROFILE:
      // Klipso Leads Icon
      if (config.KLIPSO_LEADS && config.KLIPSO_LEADS.FEATURE_ENABLED && config.KLIPSO_LEADS.hasklipsoLeadsButtonInToolbar) array.push(getKlipsoLeadsIcon()); // Contact Scan

      if (config.CONTACTS && config.CONTACTS.FEATURE_ENABLED && config.CONTACTS.hasContactsButtonInToolbar) array.push(getContactScanIcon());
      break;

    case VISITOR_PROFILE:
      // Contact Scan
      if (config.CONTACTS && config.CONTACTS.FEATURE_ENABLED && config.CONTACTS.hasContactsButtonInToolbar) array.push(getContactScanIcon());
      break;

    case EXHIBITOR_PROFILE:
      // Contact Scan
      if (config.KLIPSO_LEADS && config.KLIPSO_LEADS.FEATURE_ENABLED && config.KLIPSO_LEADS.hasklipsoLeadsButtonInToolbar) array.push(getKlipsoLeadsIcon());
      break;
  } // Search Icon


  array.push(getSearchIcon()); // Menu Icon

  array.push(getMenuIcon());
  return array;
}