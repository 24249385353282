module.exports = {
  name: "mom0922",
  client: "SAFI",
  description: "Mobile app for Maison Objet 2022",
  pluginId: "mobigeo-plugin-moo",
  validLangs: ["fr", "en"],
  version: {
    mainVersion: "9.0.1",
    buildVersion: {
      iOS: "92",
      android: "91",
      web: "92"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "mobilespotmom",
      keyStorePassword: "jee1Uu0Hieloh7ba"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};